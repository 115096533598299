const burger = document.getElementById('burger');
const header = document.getElementById('header');
const body = document.getElementsByTagName('body')[0];

burger.addEventListener('click', () => {
    header.classList.toggle('open');
})

const hero = document.getElementById('hero');

if (hero) {
  const sliderImages = hero.querySelectorAll('.hero__imagesFigure');
  const sliderContents = hero.querySelectorAll('.hero__infos div');
  const nextBtn = hero.querySelector('.next');
  const prevBtn = hero.querySelector('.prev');
  let slideIndex = 0;

  function changeSlide(forward = true) {
      sliderContents[slideIndex].classList.remove('show');
      sliderImages[slideIndex].classList.remove('show');

      if (forward) {
          slideIndex++;
          if (slideIndex == sliderContents.length) {
              slideIndex = 0;
          }
      } else {
          slideIndex--;
          if (slideIndex == -1) {
              slideIndex = sliderContents.length - 1;
          }
      }

      sliderContents[slideIndex].classList.add('show');
      sliderImages[slideIndex].classList.add('show');
  }

  nextBtn.addEventListener('click', () => changeSlide(true));
  prevBtn.addEventListener('click', () => changeSlide(false));

  setInterval(() => {
      changeSlide(true);
  }, 4000);
}


const heroSlider = document.getElementById('heroSlider');
if(heroSlider){
  //randomize nodelist
  var list = document.querySelector('.hero__images'), i;
  if(list.children.length > 2){
    list.querySelector('.show').classList.remove('show');
    for (i = list.children.length; i >= 0; i--) {
        list.appendChild(list.children[Math.random() * i | 0]);
    }
    list.querySelector('.hero__imagesFigure').classList.add('show');



    setTimeout(() => {
      const sliderImages = heroSlider.querySelectorAll('.hero__imagesFigure');
      let slideIndex = 0;
    
      setInterval(() => {
        sliderImages[slideIndex].classList.remove('show');
        slideIndex++;
        if(slideIndex == sliderImages.length){
            slideIndex = 0;
        }
        sliderImages[slideIndex].classList.add('show');
      },2500);
    },100);
  }
}

// LOGO SLIDER

if(document.getElementById('logo-slider')){
    $('#logo-slider').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
                }
              },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
        ]
    });
}

//tabs

const tabs = document.querySelector('.tabs');

if(tabs){
  tabsItems = tabs.querySelectorAll('.tabs__trigger');
  tabsItems.forEach(element => {
    element.addEventListener('click', () => {
      element.classList.toggle('active');
    })
  });
}


// masonry

window.onload = () => {
  const grids = document.querySelectorAll('.grid');

  grids.forEach(grid => {
      // For Masonry
      // new Masonry(grid, {
      //     itemSelector: '.grid-item',
      //     gutter: 20
      // });

      // For Packery
      new Packery(grid, {
          itemSelector: '.grid-item',
          gutter: 20
      });
  });

  initImageClick();
  initVideoClick();
}


//VIDEO CLICK

function initVideoClick() {
  const modal = document.getElementById('video-modal');

  if(!modal) return;

  const frame = modal.querySelector('iframe');

  let videosTriggers = document.querySelectorAll('a[data-triggervideo]');

  videosTriggers.forEach((el) => {
      el.addEventListener('click', () => {
          modal.classList.add('active');
          frame.src = 'https://www.youtube.com/embed/' + el.getAttribute('data-src');
      })
  })

  modal.addEventListener('click', () => {
      modal.classList.remove('active');
      frame.src = null;
  });
}

//IMG CLICK

function initImageClick() {
  const imagesClick = document.querySelectorAll('img[data-enlargeable]');
  const nav = document.getElementById('modalNav');
  const prev = document.getElementById('modalPrev');
  const next = document.getElementById('modalNext');

  $('img[data-enlargeable]').click(function(e){
      var src = $(this).attr('src');
      var modal;
      var indexImagesClick;
      imagesClick.forEach((el, index) => {
         if(el.getAttribute('src') == $(this).attr('src')){
              indexImagesClick = index;
         };
      });
      nav.classList.add('show');
      next.addEventListener('click', navModalNext);
      prev.addEventListener('click', navModalPrev);
      document.body.addEventListener('keydown', keyDown);
      function keyDown(e){ 
          e.preventDefault();
          var keynum;
          if(window.event) { // IE                    
            keynum = e.keyCode;
          } else if(e.which){ // Netscape/Firefox/Opera                   
            keynum = e.which;
          } 

          if(keynum == 37 || keynum == 38){
              navModalPrev();
          }
          if(keynum == 39 || keynum == 40){
              navModalNext();
          }
      };
      function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); nav.classList.remove('show'); next.removeEventListener('click', navModalNext); prev.removeEventListener('click', navModalPrev);}
      function navModalNext(){ modal.remove(); indexImagesClick++; if(indexImagesClick == imagesClick.length){ indexImagesClick = 0 }; createModal(imagesClick[indexImagesClick].getAttribute('src'));}
      function navModalPrev(){ modal.remove(); indexImagesClick--; if(indexImagesClick == -1){ indexImagesClick = imagesClick.length -1 }; createModal(imagesClick[indexImagesClick].getAttribute('src'));}
      function createModal(source) {
          modal = $('<div class="modal__bg">').css({
              background: 'url('+source+') no-repeat center',
              backgroundSize: 'contain',
              width:'100%', height:'100%',
              position:'fixed',
              zIndex:'100',
              top:'0', left:'0',
              cursor: 'zoom-out'
          }).click(function(){
              removeModal();
          }).appendTo(nav);
      }

      createModal(src);
      //handling ESC
      $('body').on('keyup.modal-close', function(e){
        if(e.key==='Escape'){ removeModal(); } 
      });
  });
}

//HEADER SHRINK & HIDE

function hideHeader() {
    //shrink
    if (window.scrollY > 75) {
        header.classList.add('smallHeader');
    }
    else {
        header.classList.remove('smallHeader');
    }
}

window.addEventListener('scroll', hideHeader);


//fakelinks (clickable object that are not a tag)
const fakelinks = document.querySelectorAll('*[fakelink]');
fakelinks.forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    window.location.href = el.getAttribute('href');
  })
})

//back to top btn

const backToTop = document.getElementById('backToTop');
const windowHeight = window.innerHeight;

backToTop.addEventListener('click', () => {
  $("html, body").animate({ scrollTop: 0 }, "slow");
})

window.addEventListener('scroll', () => {
  if(window.scrollY < windowHeight){
    backToTop.classList.add('hidden')
  }
  else{
    backToTop.classList.remove('hidden');
  }
})


//DELETE titles with empty terms

const termslisting = document.getElementById('termslisting');

if(termslisting){
  const termslistingTitles = termslisting.querySelectorAll('h2');
  termslistingTitles.forEach((el) => {
      _a = el.nextSibling.nextSibling.querySelectorAll('.partners__item');
      if(_a.length == 0){
        el.remove();
      }
  })
}

//DELETE BLANK PARTNERS 

document.querySelectorAll('.partners__items').forEach((el) => {
  if(!el.querySelector('.partners__item')){
    el.remove();
  }
}) 

// Show search
$('.header__search').on('click',function(e){

    e.preventDefault();
    $('.search .dropdown').addClass('open-search');
    $('#search').focus();

});
$(document).mouseup(function(e) 
{
    var container = $(".search .dropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        container.removeClass('open-search');
    }
});
// $('.hero__nav ul li.dropdown').on('click',function(e){
//   if (window.matchMedia("(max-width: 991px)").matches) {
    
//     if($(this).hasClass('active')){
//       e.preventDefault();
//       $('.subproducts__nav').toggleClass('active');
//     }
//   }
  
// });
